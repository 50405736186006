import React from "react"
import { PageProps } from "gatsby"
import Website from "../templates/Website"
import GamePresentationBox from "../components/GamePresentationBox"
import GameDescriptionBox from "../components/GameDescriptionBox"
import StoreBar from "../components/StoreBar"
import { SimpleGrid } from "@chakra-ui/core"
import memoQuestData from "../../content/memo-quest-data.json"
import SEO from "../components/SEO"

interface Props extends PageProps {}

const Index: React.FC<Props> = ({ location }) => {
  return (
    <Website>
      <SEO />
      <SimpleGrid columns={1} spacing={{ base: "20px", md: "25px" }}>
        <GamePresentationBox data={memoQuestData}>
          <StoreBar
            gameId={memoQuestData.gameId}
            stores={memoQuestData.stores}
          />
          <GameDescriptionBox data={memoQuestData} />
        </GamePresentationBox>
      </SimpleGrid>
    </Website>
  )
}

export default Index
